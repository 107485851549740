import {NewsProps} from "../components/News/NewsItem";
import {Department,RootInterface,Institution} from "../components/Competencies/competence";
export async function GetEventsData(id:string=""): Promise<NewsProps[]>{
  try {
    const response = await fetch(`http://45.142.36.84/api/events/${id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
export async function GetCompetenciesData(): Promise<RootInterface>{
   try {
    const response = await fetch(`http://45.142.36.84/api/skills/`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
