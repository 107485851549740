import React from "react";
import CompetenciesList from '../components/Competencies'
const Competencies = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                flex:"1 1 auto"
            }}
        >
        <CompetenciesList></CompetenciesList>
        </div>
    );
};

export default Competencies;
