import {useEffect} from 'react';
import {useState} from 'react';
import React from "react";
import {NewsProps} from '../components/News/NewsItem';
import { GetEventsData } from "../api/api";
import { useSearchParams } from 'react-router-dom';
import Markdown from 'react-markdown'
const Blogs = () => {
    const [data, setData] = useState<NewsProps[] | null>(null);
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id') ?? '';
    useEffect(() => {
        GetEventsData(id).then(response => {
            if (response) {
            setData(response);
            } else {
            console.error('No data received from API');
            return(
            <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
            >
                <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Ой Ошибка!</h2 >
            </div>
        )
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
            return(
            <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
            >
                <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Ой Ошибка!</h2 >
            </div>
        )
        });
    }, []);
    if (!data) {
        return(<div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
            >
                <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Долго думаем</h2 >
            </div>);
    }
    return (
        <div style={{marginTop:"7%",marginLeft:"10%"}}>
            <Markdown>{data[0].mddescription}</Markdown>
        </div>
    );
};

export default Blogs; 
