import React, { ReactNode } from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {Department,RootInterface,Institution} from './competence';
import {
    Eventt,
    EventImg,
    Eventh3,
    Eventp,
    Events,
    EventContainer,
} from "./CompetenciesDesigner"
import { GetCompetenciesData } from "../../api/api"
export const CompetenciesList: React.FC = () => {
    const [data, setData] = useState<RootInterface | null>(null);

    useEffect(() => {
        GetCompetenciesData().then(response => { 
          if (response) {
            setData(response); 
          } else {
            console.error('No data received from API'); 
            return(
            <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                marginTop:"10%"
            }}
            >
                <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Сервер на техническом обслуживании</h2 >
            </div>
        )
          }
        }).catch(error => { 
            console.error('Error fetching data:', error);
            return(
            <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                marginTop:"10%"
            }}
            >
                <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Сервер на техническом обслуживании</h2 >
            </div>
        )
        });
    }, []);
    
    if (!data) {
        return(<div
            style={{
                display: "inline",
                justifyContent: "centre",
                alignItems: "centre",
                marginTop:"50%"

            }}
            >
                <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Долго думаем...</h2 >
            </div>);
    }
    return (
        <div style={{marginTop:"10%", marginLeft:"5%"}}>
        {data.institutions.map((institution) => (
            <div key={institution.name}>
            <h3>{institution.name}</h3>
            <ul>
                {institution.departments.map((department) => (
                <li key={department.name}>
                    <h4>{department.name}</h4>
                    <ul>
                        {department.skills.map((skill) => (
                            <li>{skill}</li>
                        ))}
                    </ul>
                </li>
                ))}
            </ul>
            </div>
        ))}
        </div>
  );
}

export default CompetenciesList;
