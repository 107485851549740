import React from "react";

const Projects = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
        <h1 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>
            Пока тут пусто.<br/>Ждём ваших проектов
        </h1>
        </div>
    );
};

export default Projects;
