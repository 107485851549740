
import React from "react";

const Teams = () => {
    return (
        <section              style={{
                display: "flex",
                flexDirection: "column",
                alignItems:"center"
            }}>
            <h1 style={{display: "center",marginTop:"8%",textAlign:"center",color: "#657de4", fontSize: "3rem"}}>
                Студенческое Научное Общество КубГТУ
            </h1>
            <section style={{
                display: "flex",
                flexDirection: "row",
                marginTop:"5%"

            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems:"center"
            }}>
                <img src="./nophoto.png" style={{width:"30%", display: "block"}}></img>
                <h2 style={{marginTop:"5%",marginLeft:"1%",textAlign:"center",color: "#657de4"}}>Председатель СНО КубГТУ
                    <h3 style={{color: "#111111"}}>Ярутин Сергей</h3>
                </h2>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems:"center"
            }}>
                <img src="./nophoto.png" style={{width:"30%", display: "block"}}></img>
                <h2 style={{marginTop:"5%",marginLeft:"1%",textAlign:"center",color: "#657de4"}}>Заместитель Председателя СНО КубГТУ
                    <h3 style={{color: "#111111"}}>Чайка Мария</h3>
                </h2>
            </div>
            </section>
        </section>
    );
};

export default Teams; 
