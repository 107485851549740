import styled from 'styled-components';
export const FooterWraper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-botton: 0;
  padding: 1em;

`;
export const FooterBrand = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
  
`;
export const FooterTextP = styled.p`
  width: 180px;
  color: #565656;
  margin-top:0;
`;
export const FooterTextH1 = styled.p`
  padding-left: .5rem;
  color: #045ba7;
  font-weight: 500;
  font-size: 1vm
  line-height: 120%;
`;
export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  max-width: 384px;
`;
export const PcOnly = styled.div`
  @media (max-width: 1000px) {
    display:none;
  }
`;
export const FooterGridColum = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30%;
  color: var(#565656);
`;

export const FooterGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
`;
