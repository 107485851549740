
import React from "react";

const Applications = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                paddingBottom:"10em"
            }}
        >
        <div style={{marginTop:"13%", marginLeft:"5%"}}>
            <h1 style={{color: "#657de4"}}>
                Ссылки на заявления
            </h1>
            <p><a href="https://forms.yandex.ru" style={{color: "black"}}>🔬 Проектное сотрудничество</a></p>
        </div>
        </div>
    );
};

export default Applications;
 
