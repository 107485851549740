 
import React from "react";

const NotFound = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "centre",
            alignItems: "centre",
            height: "100vh",
        }}>

            <h1 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>
            Пу Пу Пу... <br/>Такой стараницы нет
            </h1>
        </div>
    );
};

export default NotFound;