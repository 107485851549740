import React, { useState } from 'react';
import styled from 'styled-components';

const NavContainer = styled.nav`
    display: flex;
    justify-content: flex-end;
    position: fixed;
    width: 100%;
    align-items: center;
    max-height: 220px;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: hsla(0, 0%, 100%, .2);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(40px);
    z-index: 3;
    @media (max-width: 1000px) {
      height: 60px; 
    }
`;

const Logo = styled.a`
    display: block;
    position: absolute;
    margin-right: 80%;
    font-size: 1.5rem;
    font-size: 2.6rem;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    color: #283364;
    transition: color 0.3s ease;

    &:hover {
      color: #657de4;
    }
`;

const MenuList = styled.ul`
  list-style: none;
  margin-right: 10%;

  display: flex;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MenuItem = styled.li`
    margin-left: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    a {
      text-decoration: none;
      color: #283364;
      transition: color 0.3s ease;

      &:hover {
        color: #657de4;
      }
    }
`;

const Hamburger = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  transform: translate(-140%, 75%);
  @media (max-width: 1000px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

  }

  span {
    display: block;
    height: 0.25rem;
    width: 2rem;
    background: #808080;
    margin-bottom: 0.5rem;
  }
`;

const MobileMenu = styled.ul`
  list-style: none;
  background-color: hsla(0, 0%, 100%, .2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(40px);
  position: fixed;
  top: 120%;
  width: 100%;
  display: none;
  z-index: 4;
  @media (max-width: 1000px) {
    display: block;
  }

  li {
    text-align: center;
    margin-bottom: 1rem;
    a {
      text-decoration: none;
      color: #283364;
      padding: 0.5rem 1rem;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #657de4;
      }
    }
  }
`;
//Im fucking late.
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        console.log("toge");
        setIsOpen(!isOpen);
    };
    return (
        <NavContainer>
        <Logo href="/">СНО</Logo>
        <MenuList>
            <MenuItem><a href="/about">Информация</a></MenuItem>
            <MenuItem><a href="/projects">Проекты</a></MenuItem>
            <MenuItem><a href="/applications">Заявки</a></MenuItem>
            <MenuItem><a href="/team">Структура</a></MenuItem>
            <MenuItem><a href="/сompetencies">Компетенции</a></MenuItem>
        </MenuList>
        <Hamburger onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
        </Hamburger>
        <MobileMenu style={{ display: isOpen ? 'block' : 'none' }}>
            <MenuItem><a href="/about">Информация</a></MenuItem>
            <MenuItem><a href="/projects">Проекты</a></MenuItem>
            <MenuItem><a href="/applications">Заявки</a></MenuItem>
            <MenuItem><a href="/team">Структура</a></MenuItem>
            <MenuItem><a href="/сompetencies">Компетенции</a></MenuItem>
        </MobileMenu>
        </NavContainer>
    );
};

export default Navbar;
