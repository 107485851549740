import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Events from "./pages/events";
import Applications from "./pages/applications";
import Teams from "./pages/teams";
import Blogs from "./pages/blogs";
import NotFound from "./pages/notfound";
import Fun from "./pages/fun";
import Footer from "./components/Footer"
import Competencies from "./pages/сompetencies";
import Projects from "./pages/projects";
function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/events" element={<Events />}/>
                <Route path="/applications" element={<Applications />}/>
                <Route path="/team" element={<Teams />} />
                <Route path="/blogs" element={<Blogs />} >
                    <Route path=":id" element={<Blogs />} />
                </Route>
                <Route path="/сompetencies" element={<Competencies />} />
                <Route path="/projects" element={<Projects />}/>'
                <Route path="/boberkyrva" element={<Fun />}/>
                <Route path="/boberkurwa" element={<Fun />}/>
                <Route path="/bobrkurwa" element={<Fun />}/>
                <Route path="*" element={<NotFound/>} />
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;
