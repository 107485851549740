import React from 'react';
import {NewsItemA,NewsItemImg,NewsItemH3,NewsItemS} from './NewsListDesiner';

export interface NewsProps {
  eventid: number;

  begindate: string;

  estimationdate: string;

  title: string;

  mddescription: string;
}
const NewsItem: React.FC<NewsProps> = ({ title, mddescription, eventid }) => {
  return (
    <>
    <NewsItemS >
      <NewsItemImg src={'/baseimage.png'} alt={title} />
      <NewsItemH3>{title}</NewsItemH3>
      <NewsItemA href={`/blogs?id=${eventid}`}>Подробнее</NewsItemA>
    </NewsItemS>
    </>
  );
};

export default NewsItem;