
import React from 'react';
import NewsList from '../components/News'
const Home = () => {
  return (
    <div style={{flex:"1 1 auto"}}>
    <section className="banner">
      <img className="kolba" src="./kolba.png"></img>
      <img className="brain" src="./brain.png"></img>
      <div className="banner-text">СТУДЕНЧЕСКОЕ<br></br>НАУЧНОЕ<br></br>ОБЩЕСТВО<br></br>КУБГТУ&nbsp;&nbsp;</div>
    </section>
    <NewsList></NewsList>
  </div>
  );
};

export default Home;
