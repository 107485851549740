import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {NewsProps} from './NewsItem';
import NewsItem from './NewsItem';
import {NewsListS, NewsContainer} from './NewsListDesiner';
import { GetEventsData } from "../../api/api"
const NewsList: React.FC = () => {
  const [data, setData] = useState<NewsProps[] | null>(null);

  useEffect(() => {
      GetEventsData().then(response => { 
        if (response) {
          setData(response); 
        } else {
          console.error('No data received from API');
          return(
          <div
          style={{
              display: "flex",
              justifyContent: "centre",
              alignItems: "centre",
              height: "100vh",
          }}
          >
              <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Сервер на техническом обслуживании</h2 >
          </div>
      )
        }
      }).catch(error => { 
          console.error('Error fetching data:', error);
          return(
          <div
          style={{
              display: "flex",
              justifyContent: "centre",
              alignItems: "centre",
              height: "100vh",
          }}
          >
              <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Сервер на техническом обслуживании</h2 >
          </div>
      )
      });
  }, []);
  if (!data) {
      return(             <div
          style={{
              display: "flex",
              justifyContent: "centre",
              alignItems: "centre",
              height: "100vh",
          }}
          >
              <h2 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Долго думаем...</h2 >
          </div>);
  }
  return (
    <NewsContainer>
      <h1 style={{marginLeft:"1em"}}>Новости и мероприятия</h1>
      <NewsListS >
          {data.map((news, index) => (
            <NewsItem key={index} {...news} />
          ))}
      </NewsListS>
    </NewsContainer>
  );
};

export default NewsList;
