import styled from 'styled-components';
export const NewsContainer = styled.section`
  display: inline-block;
  background-color: white;
  padding-top:1rem;
  width: 100%;
  max-height: 40em;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px -9px 8px 0px rgba(34, 60, 80, 0.21);
  -moz-box-shadow: 0px -9px 8px 0px rgba(34, 60, 80, 0.21);
  box-shadow: 0px -9px 8px 0px rgba(34, 60, 80, 0.21);
`;
export const NewsListS = styled.ul`
  display: flex;
  padding: 2px;
  max-height: 40em;
  overflow-x: scroll;
  list-style: none;
  background-color: white;
  z-index: 1;
  padding-top:1rem;
  gap: 20px;
  white-space:nowrap;
  margin-bottom: 10px;
  width: 100%;
  
`;

export const NewsItemS = styled.li`
  width: 25rem;
  height: 28rem;
  background-color: white;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  display: inline;
  flex-shrink: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(40px);
  margin-right: 2rem;
`;

export const NewsItemImg = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
`;

export const NewsItemH3 = styled.h3`
  margin-bottom: 10px;
  display: block;
  padding 15px;
  white-space: normal;
  overflow-wrap: break-word; 
 
`;

export const NewsItemP = styled.p`

`;

export const NewsItemA = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 15px;

  &:hover {
    background-color: #0069d9;
  }
`;
