import React from "react";

const Fun = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
        <img src="./bober.gif" style={{display: "center",margin:"auto"}}></img>
        <h1 style={{display: "center",margin:"auto",textAlign:"center",color: "#657de4"}}>Привет от ТСБ Dungeon!</h1>
        </div>
    );
};

export default Fun; 
