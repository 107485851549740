import React from 'react';
import {FooterWraper, FooterBrand,PcOnly, FooterLogo, FooterGridColum, FooterGrid, FooterTextP,FooterTextH1} from './FooterDesiner';

const Footer = () =>  {
  return (
    <footer style={{ backgroundColor: "#f1f1f1",left:"0px",marginTop: "auto" ,width:"100%"}}>
      <FooterWraper>
        <FooterBrand>
          <FooterLogo>
            <img src='./polilogo.png' style={{ display:"block",  textAlign: "center", marginLeft:"auto", marginRight:"auto",maxWidth: "82px",maxHeight: "82px"}}></img>
            <FooterTextH1>Кубанский государственный технологический университет</FooterTextH1>
          </FooterLogo>
          <FooterTextP>СНО КУБГТУ</FooterTextP>
          <FooterTextP> © 1999—2024 ФГБОУ ВО «КубГТУ»</FooterTextP>
        </FooterBrand>
        <FooterGrid>
          <FooterGridColum>
            <PcOnly>
            <h3 style={{fontWeight: "500"}}>Главная аудитория СНО КУБГТУ</h3>
            <p>Корпус К, Аудиотрия 325</p>
            </PcOnly>
          </FooterGridColum>
        </FooterGrid>

      </FooterWraper>
    </footer>
  );
};

export default Footer;
